import React from "react";
import axios from "axios";
import memoize from "memoize-one";
import retry from "async-retry";
import Erasebg from "../BulkPlayground/common/assets/erasebg.svg";
import Watermark from "../BulkPlayground/common/assets/watermark.svg";
import Upscale from "../BulkPlayground/common/assets/upscale.svg";
import Pixelbin from "../BulkPlayground/common/assets/pixelbin.svg";
import Glamar from "../BulkPlayground/common/assets/glamar.svg";
import PaymentsService from "@src/services/payments.service";
import { v4 as uuid } from "uuid";
import imageSize from "image-size";
import UTIF from "utif";

export const FILE_STATUS = Object.freeze({
    QUEUED: "Queued",
    UPLOAD_STARTED: "Upload_Started",
    UPLOAD_COMPLETED: "Upload_Complete",
    TRANSFORMATION_STARTED: "Transformation_Started",
    TRANSFORMATION_COMPLETED: "Transformation_Completed",
    FAILED: "Failed",
});

export const BATCH_STATUS = Object.freeze({
    QUEUED: "Queued", // All files within the batch are yet to start any operations.
    PARTIAL_UPLOAD: "Partial_Upload", // Some files within the batch have completed uploading, while others have failed. No file has been transformed yet.
    PARTIAL_TRANSFORMED: "Partial_Transformed", // Some files within the batch have completed transformation, while others have failed. No file is just uploaded without transformation.
    PARTIAL_MIXED: "Partial_Mixed", // Some files within the batch have completed uploading, some have completed transformation, and some have failed.
    TRANSFORMATION_COMPLETED: "Transformation_Completed", // All files within the batch have successfully completed transformation.
    UPLOAD_COMPLETED: "Upload_Completed", // All files within the batch have successfully completed uploading.
    FAILED: "Failed", // All files within the batch have failed during either upload or transformation.
    IN_PROGRESS: "In_Progress", // Files within the batch are in various statuses, none of which fits the above categories. This can include files that are queued, files that have started uploading or transforming, etc.
});

export const options = [
    {
        value: "erasebg",
        label: "Erase Background",
        icon: Erasebg,
    },
    {
        value: "upscalemedia",
        label: "Upscale Media",
        icon: Upscale,
    },
    {
        value: "watermarkremover",
        label: "Watermark Remover",
        icon: Watermark,
    },
    {
        value: "glamar",
        label: "GlamAR",
        icon: Glamar,
    },
    {
        value: "/",
        label: "Pixelbin Dashboard",
        icon: Pixelbin,
    },
];

const wait = (ms) => new Promise((res) => setTimeout(res, ms));

const MAX_RETRIES = 3;

export async function fetchLazyTransformation({ url, attempt = 0, source }) {
    const response = await axios.get(url, {
        responseType: "arraybuffer",
        withCredentials: false,
        cancelToken: source.token,
    });

    if (response.status === 202) {
        if (attempt > MAX_RETRIES) {
            throw Error("Your transformation took too long to process");
        }

        await wait(2 ** attempt * 500); // Will retry after 500, 1000, 2000 ... milliseconds, upto 2 minutes

        return fetchLazyTransformation({ url, attempt: attempt + 1, source });
    }

    return response;
}

export const getPercentage = (value, total, numOfDigits) => {
    let percentage = (value / total) * 100;
    return percentage.toFixed(numOfDigits);
};

export const SUB_HEADING_TAB = [
    {
        id: 0,
        label: "All",
        countType: "totalCount",
        fileType: "uploadProgress",
    },
    {
        id: 1,
        label: "Uploaded",
        countType: "successCount",
        fileType: "successFiles",
    },
    {
        id: 2,
        label: "Failed",
        countType: "failedCount",
        fileType: "failedFiles",
    },
];

export const createItemData = memoize((items) => ({
    items,
}));

export function fetchLazyImage(url) {
    return retry(
        async (bail) => {
            try {
                const response = await axios.get(url, {
                    withCredentials: false,
                    responseType: "blob",
                });
                if (response.status === 202) {
                    return Promise.reject(response);
                }
                return response;
            } catch (err) {
                bail(err);
            }
        },
        {
            retries: 10,
            factor: 2,
            minTimeout: 500,
        },
    );
}

const calculateTotalUsage = (addonsUsageData, subscriptionUsageData) => {
    const addonsCreditsUsed = Object.values(addonsUsageData).reduce(
        (total, addon) => total + addon.credits.used,
        0,
    );

    return subscriptionUsageData + addonsCreditsUsed;
};

const calculateTotalCredits = (addonsCreditData, subscription) => {
    const currentDate = new Date();
    let subscriptionCreditsObj = subscription?.plan?.features?.find(
        (item) => item.name === "credits",
    );
    const subscriptionCredit = subscriptionCreditsObj?.quantity;
    let totalCredits = +subscriptionCredit;

    addonsCreditData.forEach((object) => {
        if (new Date(object.expiresAt) > currentDate) {
            let addonCreditsObj = object?.addon?.features?.find((item) => item.name === "credits");
            const quantity = parseFloat(addonCreditsObj?.quantity);

            totalCredits = totalCredits + quantity;
        }
    });

    return totalCredits;
};

export function getCreditsLeft(orgId, getRoundedCredits = true) {
    /**
     * For new customers the activeSubscription call will create the new customer.
     * So it needs to complete first before other calls are made.
     */
    return PaymentsService.getActiveSubscription(orgId)
        .then(async (res) => {
            const otherResults = await Promise.all([
                PaymentsService.getSubscriptionUsage(orgId),
                PaymentsService.getCustomerAddOns(orgId),
                PaymentsService.getActiveAddonsUsage(orgId),
            ]);
            return [res, ...otherResults];
        })
        .then(([subscriptionRes, usageRes, addonsRes, addonsUsageRes]) => {
            const subscription = subscriptionRes?.data;
            const usageData = usageRes?.data;

            const totalUsageData = calculateTotalUsage(
                addonsUsageRes.data,
                usageData?.credits?.used,
            );

            const totalCredits = calculateTotalCredits(addonsRes?.data?.items, subscription);
            let creditUsed;
            if (getRoundedCredits) {
                creditUsed = Math.round(totalCredits - totalUsageData);
            } else {
                creditUsed = totalCredits - totalUsageData;
            }

            return creditUsed;
        })
        .catch((err) => {
            console.log(err);
        });
}

export const colorData = [
    {
        id: uuid(),
        value: "#ffffff",
    },
    {
        id: uuid(),
        value: "#0a0d10",
    },
    {
        id: uuid(),
        value: "#d1be15",
    },
    {
        id: uuid(),
        value: "#d42b4a",
    },
];

export const getFileDimensions = async (file) => {
    const arrayBuffer = await file.arrayBuffer();
    const uint8Array = new Uint8Array(arrayBuffer);
    if (file.type === "image/tiff") {
        const pages = UTIF.decode(arrayBuffer);
        UTIF.decodeImage(arrayBuffer, pages[0]);
        return pages[0];
    }
    return imageSize(uint8Array);
};

export const fetchBlobAndCreateFile = async (blobUrl) => {
    const response = await fetch(blobUrl);
    const blob = await response.blob();

    const file = new File([blob], `downloaded-file.${blob.type.split("/")[1]}`, {
        type: blob.type,
    });

    return file;
};
