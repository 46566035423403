export const billingConstants = {
    CANCEL_SUB_MSG:
        "When your billing cycle ends, you'll be moved to the Free plan, and your card won't be charged anymore.",
    ACTION_TYPES: {
        CHANGE_ON_CYCLE_END: "change_on_cycle_end",
        CHANGE_NOW: "change_now",
    },
    changeType: {
        UPGRADE: "upgrade",
        DOWNGRADE: "downgrade",
    },
    mostPopularPlan: "Basic",
    INTERVALS: {
        MONTHLY: "monthly",
        YEARLY: "yearly",
    },
    COUNTRY_CODES: {
        IN: "IN",
        US: "US",
    },
    CURRENCY_CODES: {
        USD: "usd",
        INR: "inr",
    },

    TABS: {
        PLAN: { name: "plan", label: "My Plan" },
        ADD_ONS: { name: "addons", label: "My Addons" },
        BILLING_DETAILS: { name: "cards", label: "My Cards" },
        INVOICES: { name: "invoices", label: "Invoices" },
        ADDRESS: { name: "address", label: "Billing Address" },
    },

    cardElementStyle: {
        base: {
            backgroundColor: "#171921",
            fontFamily: "Inter, sans-serif",
            color: "white",
            fontSize: "14px",
            iconColor: "white",
            "::placeholder": {
                color: "#AAABC5",
            },
        },
    },
    ITEM_TYPES: {
        SUBSCRIPTION: "subscription",
        ADDON: "addon",
    },
    FEATURES: {
        CREDITS: "credits",
        STORAGE: "storage",
    },
    BILLING_TYPES: {
        PREPAID: "prepaid",
        POSTPAID: "postpaid",
    },

    SELLER_STATE: "Maharashtra",
};
export const TABS_ITEMS = [
    {
        name: "Monthly",
        identifier: "monthly",
    },
    {
        name: "Yearly",
        identifier: "yearly",
    },
];
export const creditDetailConstant = {
    transformations: [
        {
            name: "EraseBG",
            subtext: "Background removal by",
            link: "https://www.erase.bg/",
            display: "Erase.bg",
        },
        {
            name: "SuperResolution",
            subtext: "Image Enhancement by",
            link: "https://www.upscale.media/",
            display: "Upscale.media",
        },
        {
            name: "WatermarkRemoval",
            subtext: "Watermark Removal by",
            link: "https://www.watermarkremover.io/",
            display: "WatermarkRemover.io",
        },
        {
            name: "WatermarkDetection",
            subtext: "Watermark Detection",
            link: "",
            display: "",
        },
    ],
};
